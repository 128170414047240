/** 
 * (saas)元宇宙UGC工具
 */
<template>
  <v-card class="vrLiveBroadcast">
    <v-banner
      single-line
      height="500"
      class="banner"
    >
          <v-img
            src="https://h5.ophyer.cn/official_website/banner/saas-ban-01.png"
            height="100%"
            class="d-flex align-center"
            >
            <v-container color="transparent" class="px-6 max-width-1200 height-per-100 d-flex align-center">
              <div class="flex-grow-1">
                <v-card color="transparent" class="ml-0" flat>
                  <v-card-title class="pa-0 font-size-banner-title family-Bold">
                    <div>
                      <div class="pt-2 color-FFF" style="font-weight: 500; ">元宇宙UGC工具</div>
                    </div>
                  </v-card-title>
                  <v-card-title class="pa-0 font-size-banner-tip mt-8 color-FFF">
                    完整的产品矩阵助力用户高效产出优秀的内容
                  </v-card-title>
                </v-card>
              </div>
              <div class="flex-grow-1">
                <v-img src="https://h5.ophyer.cn/official_website/banner/saas-ban-02.png"></v-img>
              </div>
            </v-container>
          </v-img>
    </v-banner>
    <!-- <v-banner
        single-line
        height="400"
        class="banner"
      >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/bannerAutomobileIndustry400.jpg"
          height="100%"
          :transition="false"
          :class="imgLoad ? 'v-image__image--animation':''">
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                size="80"
                color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="pa-0 max-width-1200" style="margin-top: 130px;">
          <v-card color="transparent" flat>
            <v-card-title class="white--text pa-0 font-size-50 font-weight-bold font-size-banner-title">
              元宇宙UGC工具
            </v-card-title>
            <v-card-title class="white--text part1 pa-0 mt-7 font-size-banner-tip" style="line-height:30px">
              完整的产品矩阵助力用户高效产出优秀的内容
            </v-card-title>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>   -->
    <!-- 2. 你的元宇宙从强大的工具开始-->
    <v-card class="warp1" :flat="true">
      <div class="public-title">
        <p>PRODUCT ADVANTAGES</p>
        <div>
          <span>你的元宇宙从强大的工具开始</span>
        </div>
      </div>
      <div class="warp con">
        <v-row no-gutters justify='space-between'>
          <v-col v-for="(v, k) in data.wrap1" :key="k" cols="12" sm="3" >
            <div class="box pt-5">
              <img :src='v.img' />
              <v-card-title> {{ v.title }} </v-card-title>
              <v-card-text class="tip" v-html="v.text"></v-card-text>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <!-- 3.产品体系 -->
    <v-card class="warp warp2" :flat="true">
      <!-- <div class="public-title">
        <p>PRODUCT SYSTEM </p>
        <div>
          <span>基础教育产品体系</span>
          <span>学科类、素质类及XR未来教室 教学+运营+管理全面信息化</span>
        </div>
      </div> -->
      <v-tabs v-model="tab" centered color="#0084ff" class="g-tabs mt-8">
        <v-tab v-for="(v, k) in data.wrap2.list" :key="k">
          <b>{{ v.title }}</b>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(v, k) in data.wrap2.list" :key="k" transition="fade-transition">
          <div class="box">
            <transition name="slide-fade" appear >
              <div class="left" v-show="tab == k"><img :src="v.img" /></div>
            </transition>
            <transition name="slide-fade-reverse" appear >
              <div class="right" v-show="tab == k">
                <v-card-text class="title" v-text="v.h2" />
                <v-card-text class="tip" v-text="v.content" v-html="v.content" />
              </div>
            </transition>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <!-- 4.saas生态 -->
    <v-card class="warp3" :flat="true" style="margin-top: 40px">
      <div class="public-title">
        <p>SAAS ECOLOGY</p>
        <div>
          <span>SaaS生态</span>
        </div>
      </div>
      <div class="box warp">
        <div class="left">
          <v-card-title class="title">元宇宙素材UGC平台</v-card-title>
          <v-card-text class="tip">不仅可以创作元宇宙空间，你创造出的任意内容<br/>都可以在UGC平台分享</v-card-text>

        </div>
        <div class="right"><img src="https://h5.ophyer.cn/official_website/other/saasshengtai.png" /></div>
      </div>
    </v-card>
    <!-- 5.行业方案，核心能力，技术支撑 （一张图）-->
    <!-- <v-card flat class="con3">
      <div style="height:1px;margin-top:50px;"></div>
      <div class="box mt-3">
        <v-img
          src="https://h5.ophyer.cn/official_website/other/home-hzhb3.png" contain
        />
      </div>
    </v-card> -->
    <!-- 6. 趣味内容-->
    <!-- <v-card class="warp warp2" :flat="true">
      <div class="public-title">
        <p>{{ data2.wrap2.titleEng }} </p>
        <div>
          <span>{{data2.wrap2.title}}</span>
        </div>
      </div>
      <v-tabs v-model="tab" centered color="#0084ff" class="g-tabs mt-8">
        <v-tab v-for="(v, k) in data2.wrap2.list" :key="k">
          <b>{{ v.tabTitle }}</b>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(v, k) in data2.wrap2.list" :key="k" transition="fade-transition">
          <div class="box">
            <transition name="slide-fade" appear >
              <div class="left" v-show="tab == k"><img :src="v.img" /></div>
            </transition>
            <transition name="slide-fade-reverse" appear >
              <div class="right" v-show="tab == k">
                <v-card-text class="title" v-text="v.childrenTitle" />
                <v-card-text class="tip" v-text="v.children" v-html="v.children" />
                 <p class="mb-3 tip"  v-for="(i, j) in v.children" :key="j">{{i}}</p>
              </div>
            </transition>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card> -->
    <v-card class="warp1" :flat="true">
      <div class="public-title">
        <p>{{data.wrap3.titleEng}}</p>
        <div>
          <span>{{data.wrap3.title}}</span>
        </div>
      </div>
      <div class="warp con">
        <v-row no-gutters justify='space-between'>
          <v-col v-for="(v, k) in data.wrap3.list" :key="k" cols="12" sm="3" >
            <div class="box pt-5">
              <img :src='v.img' />
              <v-card-title> {{ v.tabTitle }} </v-card-title>
              <v-card-text class="tip"> {{ v.childrenTitle }} </v-card-text>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <!-- 7. 丰富的AR能力 -->
    <v-card flat class="warp warp4 warp-box">
      <div class="public-title mb-9">
        <p>AR ABILITY</p>
        <div>
          <span style="font-size:20px;">丰富的AR能力</span>
          <span>图像、物体、面部等多种识别方式 互动体验更强</span>
        </div>
      </div>
      <v-container class="threeCon">
        <v-row>
          <v-col cols="3" v-for="n in data1.wrap1.cards" :key="n.id" >
              <div class="imgWrap">
                  <img class="img_three" :src="n.src" alt="">
                  <v-card-text v-text="n.tit" class="threeTit pa-0 text-align-center mt-3"></v-card-text>
              </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <!-- 9. 出色的VR编辑器 -->
    <v-card class="warp warp9" :flat="true">
      <div class="public-title">
        <p>VR EDITOR</p>
        <div>
          <span style="font-size:20px;">出色的VR编辑器</span>
        </div>
      </div>
      <!-- <div class="box_two">
        <div class="left">
          <v-card-text class="title">3D环物内容编辑</v-card-text>
          <v-card-text class="tip" style="margin-top:21px">一键抠图去除背景，多种类型热点随意添加，支持多种输出<br/>格式，更可一键分享</v-card-text>
        </div>
        <div class="right"><img src="https://h5.ophyer.cn/official_website/other/saasEdit-01.png" /></div>
      </div> -->
      <div class="box" style="margin-top:70px;">
        <div class="left"><img src="https://h5.ophyer.cn/official_website/other/saasEdit-02.png" /></div>
        <div class="right">
          <v-card-text class="title">3D模型内容编辑</v-card-text>
          <v-card-text class="tip" >支持gtif、glb、fbx、obj格式的模型素材，可以随意调整视<br/>角，添加热点，还可以修改模型的背景</v-card-text>
        </div>
      </div>
      <div class="box_two">
        <div class="left">
          <v-card-text class="title">全景图编辑</v-card-text>
          <v-card-text class="tip" style="margin-top:21px">直播+支持场景管理、多样式热点、遮罩、沙盘、特效、自定义<br/>品牌/控件、一键分享功能</v-card-text>
        </div>
        <div class="right"><img src="https://h5.ophyer.cn/official_website/other/saasEdit-03.png" /></div>
      </div>
      <div class="box mb-11">
        <div class="left"><img src="https://h5.ophyer.cn/official_website/other/saasEdit-04.png" /></div>
        <div class="right">
          <v-card-text class="title">全景视频编辑</v-card-text>
          <v-card-text class="tip" >支持多种规格清晰度视频，在视频内部任意嵌入热点，还<br/>可导出APK</v-card-text>
        </div>
      </div>
      <div class="box_two">
        <div class="left">
          <v-card-text class="title">景深漫游编辑</v-card-text>
          <v-card-text class="tip" style="margin-top:21px">直播+不仅支持简单的平面VR图还支持基于3D场景制作包含空间<br/>信息的景深图组，生成可对外分享或在VR头显上体验的<br/>景深漫游作品</v-card-text>
        </div>
        <div class="right"><img src="https://h5.ophyer.cn/official_website/other/saasEdit-05.png" /></div>
      </div>
    </v-card>
    <!-- 10. AR打卡-沉浸式游览交互互动小程序游戏-->
    <v-card class="warp warp3" :flat="true" style="margin-top: 50px;background:#fff ">
      <div class="public-title">
        <p>SAAS ECOLOGY</p>
        <div>
          <span>AR打卡-沉浸式游览交互互动小程序游戏</span>
        </div>
      </div>
      <div class="box">
        <div class="left">
          <v-card-text class="tip">1. 针对景区、活动展览开发制作的线上线下一体 沉浸式游览交互互动的小程序游戏</v-card-text>
          <v-card-text class="tip">2. 针对不同旅游人群特点为景区 设计旅游路线规划 </v-card-text>
          <v-card-text class="tip">3. 利用互动打卡游戏设置打卡点，为景区指定商家 引流，促进商家消费以及互动抽奖提升景区二次传播</v-card-text>
          <v-card-text class="tip">4. 利用AR技 术针对景区为修复文物进行场景复原，传播景区历史文化</v-card-text>
        </div>
        <div class="right"><img src="https://h5.ophyer.cn/official_website/other/saasAR.png" /></div>
      </div>
    </v-card>
    <!-- 11.VR直播-虚拟IP-->
    <v-container style="padding-left: 5%;padding-right: 5%;">
      <v-card elevation="0">
        <div class="public-title" style="margin-top:80px">
            <p>IMPROVE TEACHING QUALITY</p>
            <div>
              <span>AR/VR直播-虚拟IP，语音互动，自定义720VR场景，想在哪直播就在哪直播</span>
            </div>
        </div>
        <v-container>
          <v-row class="justify-center">
            <v-col cols="9">
              <v-row>
                <v-col cols="4"  v-for="(card,index) in data.sheetList" :key="index">
                    <v-card elevation="0">
                      <div class="d-flex justify-center"><v-img class="flex-grow-0" :src="card.src" width="120" height="120">
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                              indeterminate
                              size="80"
                              color="red lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template></v-img></div>
                      <v-card-title class="font-size-24 font-weight-bold justify-center text-align-center" v-text="card.title"></v-card-title>
                      <v-card-text class="font-size-14 text-align-center"  style="color:#666" v-text="card.content"></v-card-text>
                    </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
  </v-card>
</template>

<script>
import contentData from '@/static/contentData.js'
import textData from '@/static/textData.js';
let _this;
export default {
  name: "saasMetaverse",
  data(){
    return{
      data: contentData.saasMetaverse,
      data1: contentData.solutionAR,
      data2: textData.WmyHome,
      detailIndex: 1,
      bannerLoad: false,
      imgLoad: false,
      tab:null,
      vtab: null,
      tab1: null,
      tab2: null,
    
    }
  },
  mounted(){
    _this = this;
    this.bannerLoad = true;
  },
  methods: {
    handleLoad: ()=>{
      _this.imgLoad = true;
      console.log("ewf")
    }
  }
}
</script>

<style lang="scss" scoped>
.public-title{
  height: unset;
  margin-bottom: 0px;
}
.warp {
  width: 1200px;
  margin: 0 auto;
  img{
    border-radius: 15px;
  }
}
.con3 {
  width: 100%;
  background-color: #f5f9fd;
  .box {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 30px;
    // margin-top:50px;
  }
} 
.product-part2{
  max-width: 1200px;
  margin: 0 auto;
  img{
    width: 120px;
    height: 120px;
    border-radius: 5px;
  }
  .text{
    width: 266px;
    margin: 0 auto;
  }
  .tit{
    font-weight: 500;
    font-size: 20;
    justify-content: center;
    color: #333;
  }
}
.warp2 {
  .box {
    width: 1024px;
    margin: 50px auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left {
    margin-right: 55px;
    img {
      width: 400px;
      height: 260px;
      border-radius: 30px;
      object-fit: cover
    }
  }
  .right {
    text-align: start;
    //font-family: SourceHanSansCN-Regular;
    padding-top: 30px;
    .v-card__text {
      padding: 0;
    }
    .title {
      font-family: Source Han Sans CN;
      font-size: 24px !important;
      font-weight: 600 !important;
    }
    .tip {
      margin: 20px 0;
      font-size: 16px !important;
      color: rgb(102, 102, 102) !important;
    }
    .text {
      font-size: 14px;
      line-height: 26px;
    }
  }
}
.warp1 {
  // background: #f8f8f8;
  padding-top: 60px !important;
  .public-title{
    margin-top: 0px;
  }
  .con {
    margin-top: 20px;
  }
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    img {
      width: 120px;
      height: 120px;
    }
  }
  .tip {
    width: 266px;
    opacity: 0.6;
  }
}
.warp3 {
  background: #f8f8f8;
  padding-top: 50px !important;
  padding-bottom: 20px;
  .public-title{
    margin-top: 0px;
  }
  .box {
    width: 1024px;
    margin: 60px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .right{
    img {
      width: 400px;
      height: 260px;
      border-radius: 30px;
    }
  }
  .left {
    text-align: start;
    margin-right: 55px;
    .v-card__text {
      padding: 0;
    }
    .title {
      font-size: 24px !important;
      font-weight: 600 !important;
    }
    .tip {
      margin: 10px 0;
      padding-left: 16px;
      font-size: 16px !important;
      color: rgb(102, 102, 102) !important;
      &:last-child{
        margin:  0;
      }
    }
    .text {
      font-size: 14px;
      line-height: 23px;
    }
  }
}
.warp4{
  .threeCon{
    .imgWrap{
      justify-content: center;
      text-align: center;
      margin-bottom: 10px;
      .img_three{
        text-align: center;
        border-radius: 5px;
        width: 100px;
        height: 100px;
      }
    }
    .threeTit{
      font-size: 20px;
      font-weight: 400;
      color: #333333;
      line-height: 44px;
    }
  }
}
.warp9 {
   .v-card__subtitle, .v-card__text ,.text-subtitle-1,.v-card__title{
     line-height: 26px !important;
   }
   .public-title{
    margin-top: 45px  !important;
  }
  .box {
    width: 1024px;
    margin: 32px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .left {
      margin-right: 55px;
      img {
        width: 400px;
        height: 260px;
        border-radius: 30px;
      }
    }
   .right {
     text-align: start;
     //font-family: SourceHanSansCN-Regular;
     padding-top: 30px;
     .v-card__text {
       padding: 0;
     }
     .title {
       font-family: Source Han Sans CN;
       font-size: 24px !important;
       font-weight: 600 !important;
     }
     .tip {
       margin: 21px 0;
       font-size: 16px !important;
       color: rgb(102, 102, 102) !important;
     }
     .text {
       font-size: 14px;
       line-height: 26px;
     }
   }
  }
  .box_two {
    width: 1024px;
    margin: 50px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .right{
      img {
        width: 400px;
        height: 260px;
        border-radius: 30px;
      }
    }
   .left {
     text-align: start;
     //font-family: SourceHanSansCN-Regular;
     margin-right: 55px;
     .v-card__text {
       padding: 0;
     }
     .title {
       font-family: Source Han Sans CN;
       font-size: 24px !important;
       font-weight: 600 !important;
     }
     .tip {
       margin: 10px 0;
       font-size: 16px !important;
       color: #666;
       &:last-child{
         margin:  0;
       }
     }
     .text {
       font-size: 14px;
       line-height: 23px;
     }
   }
  }
  
}
</style>